import { successConstant } from "../constants/constant"


const initialState = {
    summarySheet:[],
    galleryData: [],
    lotteryData: [],
    isAuthenticated: false,
    error: null,
}

export default function successReducer(state = { ...initialState }, action) {
    switch (action.type) {
        // Authentication
        case successConstant.GET_SUMMARY_SHEET_R:
            return { ...state }
        case successConstant.GET_SUMMARY_SHEET_S:
            return { ...state, summarySheet: action.payload.data }
        case successConstant.GET_SUMMARY_SHEET_F:
            return initialState.summarySheet
        
        // Get summary sheet
        case successConstant.SUCCESS_LOGIN_R:
            return { ...state }
        case successConstant.SUCCESS_LOGIN_S:
            return { ...state, isAuthenticated: true, error: null };
        case successConstant.SUCCESS_LOGIN_F:
            return { ...state, isAuthenticated: false, error: action.payload };

        // Save summary sheet
        case successConstant.SAVE_SUMMARY_SHEET_R:
            return { ...state }
        case successConstant.SAVE_SUMMARY_SHEET_S:
            return { ...state, summarySheet: action.payload.data }
        case successConstant.SAVE_SUMMARY_SHEET_F:
            return initialState.summarySheet

        // Delete summary sheet
        case successConstant.DELETE_SUMMARY_SHEET_R:
            return { ...state }
        case successConstant.DELETE_SUMMARY_SHEET_S:
            const summarySheet = [...state.summarySheet]
            const updatedList = summarySheet.filter((s) => s.id !== action.data)
            return { ...state, summarySheet: updatedList }
        case successConstant.DELETE_SUMMARY_SHEET_F:
            return initialState.summarySheet

        // Get gallery
        case successConstant.GET_GALLERY_DATA_R:
            return { ...state }
        case successConstant.GET_GALLERY_DATA_S:
            return { ...state, galleryData: action.payload.data }
        case successConstant.GET_GALLERY_DATA_F:
            return initialState.galleryData

        // Save gallery
        case successConstant.SAVE_GALLERY_DATA_R:
            return { ...state }
        case successConstant.SAVE_GALLERY_DATA_S:
            return { ...state, galleryData: action.payload.data }
        case successConstant.SAVE_GALLERY_DATA_F:
            return initialState.galleryData

        // Delete gallery
        case successConstant.DELETE_GALLERY_DATA_R:
            return { ...state }
        case successConstant.DELETE_GALLERY_DATA_S:
            const galleryData = [...state.galleryData]
            const updatedgalleryData = galleryData.filter((s) => s.id !== action.data)
            return { ...state, galleryData: updatedgalleryData }
        case successConstant.DELETE_GALLERY_DATA_F:
            return initialState.galleryData

        // Get Lotteries
        case successConstant.GET_LOTTERY_DATA_R:
            return { ...state }
        case successConstant.GET_LOTTERY_DATA_S:
            return { ...state, lotteryData: action.payload.data }
        case successConstant.GET_LOTTERY_DATA_F:
            return initialState.lotteryData

        // Save Lotteries
        case successConstant.SAVE_LOTTERY_DATA_R:
            return { ...state }
        case successConstant.SAVE_LOTTERY_DATA_S:
            return { ...state, lotteryData: action.payload.data }
        case successConstant.SAVE_LOTTERY_DATA_F:
            return initialState.lotteryData

        // Delete Lotteries
        case successConstant.DELETE_LOTTERY_DATA_R:
            return { ...state }
        case successConstant.DELETE_LOTTERY_DATA_S:
            const lotteryData = [...state.lotteryData]
            const updated_lotteryData = lotteryData.filter((s) => s.id !== action.data)
            return { ...state, lotteryData: updated_lotteryData }
        case successConstant.DELETE_LOTTERY_DATA_F:
            return initialState.lotteryData

        //Update summary reducer
        case successConstant.UPDATE_SUMMARY_REDUCER:
            return { ...state, summarySheet : action.data }

        //Update gallery reducer
        case successConstant.UPDATE_GALLERY_REDUCER:
            return { ...state, galleryData: action.data}
        
        //Update lottery reducer
        case successConstant.UPDATE_LOTTERY_REDUCER:
            return { ...state, lotteryData: action.data }
            
        default:
            return state
    }
}

