
const style = () => ({

    loginContainer : {
        background: 'radial-gradient(ellipse at 150%, #b85f1f, #013fc5) no-repeat',
        height: '105vh',
        padding: '4vh 8vw',
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            height: '100%',
        },
    },
    loginCard:{
        borderRadius: '8px',
        background: '#fff',
        padding: '10px'
    }
})

export default style