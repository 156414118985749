import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, IconButton, Input, MenuItem, Select, TextField, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import moment from 'moment';

import successLogo from "../../assets/logo/logo.png";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { successAction } from "../../redux/actions/successAction";
import { updateAction } from "../../redux/actions/updateAction";
import { successConstant } from "../../redux/constants/constant";
import styles from "./styles";
import { useNavigate } from "react-router-dom";

function Dashboard(props) {

    const { classes } = props;

    const [file, setFile] = useState();
    const [galleryFile, setGalleryFile] = useState();
    const [lotteryFile, setLotteryFile] = useState();
    const { summarySheet, galleryData, lotteryData } = useSelector(s => s)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(successAction.getSummarySheet())
        dispatch(successAction.getGalleryData())
        dispatch(successAction.getLotteryData())
    }, [])

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleGalleryFileChange = (e) => {
        setGalleryFile(e.target.files[0]);
    };

    const handleLotteryFileChange = (e) => {
        setLotteryFile(e.target.files[0]);
    };

    const handleFileUpload = () => {
        if (file && summarySheet?.filename !== "" && summarySheet?.sheet_date?.hasOwnProperty('$d')) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('title', summarySheet?.filename)
            formData.append('sheet_date', summarySheet?.sheet_date["$d"])
            
            dispatch(successAction.uploadSummarySheet(formData, () => {
                dispatch(successAction.getSummarySheet())
            }))
        } else {
            alert('Enter title/date/select file to upload')
        }
    };

    const handleGalleryFileUpload = () => {
        if (galleryFile && galleryData?.gallery_title !== "") {
            const formData = new FormData();
            formData.append('file', galleryFile);
            formData.append('title', galleryData?.gallery_title)
            dispatch(successAction.saveGalleryData(formData, () => {
                dispatch(successAction.getGalleryData())
            }))
        } else {
            alert('Enter title/select file to upload')
        }
            
    };

    const handleLotteryFileUpload = () => {
        if (lotteryFile && lotteryData?.lottery_name !== "" && lotteryData?.lottery_prize !== "") {
            const formData = new FormData();
            formData.append('file', lotteryFile);
            formData.append('title', lotteryData?.lottery_name)
            formData.append('lottery_prize', lotteryData?.lottery_prize)
            formData.append('type', lotteryData?.type)

            dispatch(successAction.saveLotteryData(formData, () => {
                dispatch(successAction.getLotteryData())
            }))
        } else {
            alert('Enter title/enter prize amount/select file to upload')
        }
    };

    const updateSummaryData = ({ name, value }) => {
        let updatedData = { ...summarySheet, [name]: value };
        dispatch(updateAction.update(successConstant.UPDATE_SUMMARY_REDUCER, updatedData))
    }

    const updateGalleyData = ({ name, value }) => {
        let updatedData = { ...galleryData, [name]: value };
        dispatch(updateAction.update(successConstant.UPDATE_GALLERY_REDUCER, updatedData))
    }

    const updateLotteryData = ({ name, value }) => {
        let updatedData = { ...lotteryData, [name]: value };
        dispatch(updateAction.update(successConstant.UPDATE_LOTTERY_REDUCER, updatedData))
    }

    const handleSummaryDelete = (summary) => {
        const { id, image_file } = summary
        dispatch(successAction.deleteSummarySheet({ id: id, path: image_file }))
    }

    const handleGalleryDelete = (gallery) => {
        const { id, gallery_file } = gallery
        dispatch(successAction.deleteGalleryData({ id: id, path: gallery_file }))
    }

    const handleLotteryDelete = (lottery) => {
        const { id, lottery_file } = lottery
        dispatch(successAction.deleteLotteryData({ id: id, path: lottery_file }))
    }

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/admin-login', { replace: true });
    }

    const handleLotteryCategory = (event) => {
        const name = event.target.name
        const value = event.target.value
        let updatedData = { ...lotteryData, [name]: value };
        dispatch(updateAction.update(successConstant.UPDATE_LOTTERY_REDUCER, updatedData))
    }
    
    return (
        <Grid container className={classes.mainContainer} spacing={5}>
            <Grid item xs={12}>
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <img src={successLogo} alt='logo' />
                    </Grid>
                    <Grid>
                        <Button className='contactBtn' variant='outlined' onClick={() =>(handleLogout())}>Logout</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction='column' spacing={4}>
                    <Grid item>
                        <Typography variant="h5" className="primary">Result Sheet</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    label="Filename"
                                    className={classes.uploadForm}
                                    fullWidth
                                    value={summarySheet?.filename}
                                    onChange={(e) => updateSummaryData({ name: 'filename', value: e.target.value })}
                                />
                            </Grid>
                            <Grid item>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        value={summarySheet?.sheet_date}
                                        className={classes.uploadForm}
                                        onChange={(newValue) => updateSummaryData({ name: 'sheet_date', value: newValue })}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item>
                                <Input variant="outlined" className={classes.uploadInput} type="file" name="file" onChange={handleFileChange} />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={handleFileUpload}>
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {Array.isArray(summarySheet) && summarySheet?.map((result, index) => (
                        <Grid item key={`${result.id}_${index}`}>
                            <Grid container alignItems='center' justifyContent="space-between" className={classes.uploadCard}>
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>Image Name:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>{result.title}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>Image Date:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>{(moment(result.sheet_date).format('DD MMM, YYYY'))}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>Attached Image:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>{result.file_name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => { handleSummaryDelete(result) }}>
                                        <DeleteForeverIcon sx={{ color: '#fff' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                    }

                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction='column' spacing={4}>
                    <Grid item>
                        <Typography variant="h5" className="primary">Gallery</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    // margin="normal"
                                    className={classes.uploadForm}
                                    label="Filename"
                                    fullWidth
                                    value={galleryData?.gallery_title}
                                    onChange={(e) => updateGalleyData({name:'gallery_title', value:e.target.value})}
                                />
                            </Grid>
                            <Grid item>
                                <Input className={classes.uploadInput} type="file" onChange={handleGalleryFileChange} />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={handleGalleryFileUpload}>
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {Array.isArray(galleryData) && galleryData?.map((result, index) => (
                        <Grid item key={`${result.id}_${index}`}>
                            <Grid container alignItems='center' justifyContent="space-between" className={classes.uploadCard}>
                                <Grid item>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>Image Name:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>{result.title}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>Attached Image:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>{result.file_name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => { handleGalleryDelete(result) }}>
                                        <DeleteForeverIcon sx={{ color: '#fff' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction='column' spacing={4}>
                    <Grid item>
                        <Typography variant="h5" className="primary">Lotteries</Typography>
                    </Grid>
                    <Grid item>
                        <Grid container alignItems='center' spacing={3}>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    // margin="normal"
                                    className={classes.uploadForm}
                                    label="Lottery name"
                                    fullWidth
                                    value={lotteryData?.lottery_name}
                                    onChange={(e) => updateLotteryData({name:'lottery_name', value:e.target.value})}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    // margin="normal"
                                    className={classes.uploadForm}
                                    label="Lottery prize"
                                    fullWidth
                                    value={lotteryData?.lottery_prize}
                                    onChange={(e) => updateLotteryData({ name: 'lottery_prize', value: e.target.value })}
                                />
                            </Grid>
                            <Grid item>
                                <Select
                                    id="lottery_category"
                                    value={lotteryData?.type}
                                    label="Lottery Category"
                                    name="type"
                                    placeholder="Lottery Category"
                                    className={classes.uploadInput}
                                    onChange={handleLotteryCategory}
                                >
                                    <MenuItem value={1}>Daily Lottery</MenuItem>
                                    <MenuItem value={2}>Bumper Lottery</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item>
                                <Input className={classes.uploadInput} type="file" onChange={handleLotteryFileChange} />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" onClick={handleLotteryFileUpload}>
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {Array.isArray(lotteryData) && lotteryData?.map((result, index) => (
                        <Grid item key={`${result.id}_${index}`}>
                            <Grid container alignItems='center' justifyContent="space-between" className={classes.uploadCard}>
                                <Grid item >
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>Lottery Name:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>{result.title}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>Lottery Prize:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>{result.lottery_prize}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>Lottery Category:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>{result.type === 1 ? 'Daily' : 'Bumper'}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>Attached Image:</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.addressTypo}>{result.file_name}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => { handleLotteryDelete(result) }}>
                                        <DeleteForeverIcon sx={{ color: '#fff' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(Dashboard)