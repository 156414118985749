import { successConstant } from '../constants/constant';
import { successService } from '../service/successService';

export const successAction = {
    getSummarySheet,
    getGalleryData,
    getLotteryData,
    uploadSummarySheet,
    saveGalleryData,
    saveLotteryData,
    deleteSummarySheet,
    deleteGalleryData,
    deleteLotteryData,
    loginDetail
}

function getSummarySheet() {
    return dispatch => {
        dispatch({ type: successConstant.GET_SUMMARY_SHEET_R });
        successService.getSummarySheet()
            .then(data => dispatch({ type: successConstant.GET_SUMMARY_SHEET_S, payload: data }))
            .catch(error => dispatch({ type: successConstant.GET_SUMMARY_SHEET_F, payload: error }));
    };
};

function uploadSummarySheet(formData, callBack = () => { }) {
    return dispatch => {
        dispatch({ type: successConstant.UPLOAD_SUMMARY_SHEET_R });
        successService.uploadSummarySheet(formData)
            .then(data => {
                dispatch({ type: successConstant.UPLOAD_SUMMARY_SHEET_S, payload: data })
                callBack()
            })
            .catch(error => dispatch({ type: successConstant.SAVE_SUMMARY_SHEET_F, payload: error }));
    };
};

function deleteSummarySheet(params, callBack = () => { }) {
    return dispatch => {
        dispatch({ type: successConstant.DELETE_SUMMARY_SHEET_R });
        successService.deleteSummarySheet(params)
            .then(data => {
                dispatch({ type: successConstant.DELETE_SUMMARY_SHEET_S, data: params.id })
                callBack()
        })
            .catch(error => dispatch({ type: successConstant.DELETE_SUMMARY_SHEET_F, payload: error }));
    };
};


function getGalleryData() {
    return dispatch => {
        dispatch({ type: successConstant.GET_GALLERY_DATA_R });
        successService.getGalleryData()
            .then(data => dispatch({ type: successConstant.GET_GALLERY_DATA_S, payload: data }))
            .catch(error => dispatch({ type: successConstant.GET_GALLERY_DATA_F, payload: error }));
    };
};

function saveGalleryData(formData, callBack = () => { }) {
    return dispatch => {
        dispatch({ type: successConstant.SAVE_GALLERY_DATA_R });
        successService.saveGalleryData(formData)
            .then(data => {
                dispatch({ type: successConstant.SAVE_GALLERY_DATA_S, payload: data })
                callBack()
        })
            .catch(error => dispatch({ type: successConstant.SAVE_GALLERY_DATA_F, payload: error }));
    };
};

function loginDetail(body, callBack = () => { }){
    return dispatch => {
        dispatch({ type: successConstant.SUCCESS_LOGIN_R });
        successService.loginDetail(body).then(data => {
            localStorage.setItem('token', data.token);
            dispatch({ type: successConstant.SUCCESS_LOGIN_S, payload: data })
            callBack()
        })
            .catch(error => dispatch({ type: successConstant.SUCCESS_LOGIN_F, payload: error }));
    }
}


function deleteGalleryData(params, callBack = () => { }) {
    return dispatch => {
        dispatch({ type: successConstant.DELETE_GALLERY_DATA_R });
        successService.deleteGalleryData(params)
            .then(data => {
                dispatch({ type: successConstant.DELETE_GALLERY_DATA_S, data: params.id })
                callBack()
        })
            .catch(error => dispatch({ type: successConstant.DELETE_GALLERY_DATA_F, payload: error }));
    };
};

function getLotteryData() {
    return dispatch => {
        dispatch({ type: successConstant.GET_LOTTERY_DATA_R });
        successService.getLotteryData()
            .then(data => dispatch({ type: successConstant.GET_LOTTERY_DATA_S, payload: data }))
            .catch(error => dispatch({ type: successConstant.GET_LOTTERY_DATA_F, payload: error }));
    };
};

function saveLotteryData(formData, callBack = () => { }) {
    return dispatch => {
        dispatch({ type: successConstant.SAVE_LOTTERY_DATA_R });
        successService.saveLotteryData(formData)
            .then(data => {
                dispatch({ type: successConstant.SAVE_LOTTERY_DATA_S, payload: data })
                callBack()
            })
            .catch(error => dispatch({ type: successConstant.SAVE_LOTTERY_DATA_F, payload: error }));
    };
};

function deleteLotteryData(params, callBack = () => { }) {
    return dispatch => {
        dispatch({ type: successConstant.DELETE_LOTTERY_DATA_R });
        successService.deleteLotteryData(params)
            .then(data => {
                dispatch({ type: successConstant.DELETE_LOTTERY_DATA_S, data: params.id })
                callBack()
            })
            .catch(error => dispatch({ type: successConstant.DELETE_LOTTERY_DATA_F, payload: error }));
    };
};