import React, { useState } from 'react';
import { Grid, Modal, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { _api } from "../../config/environment";
import { isMobile } from 'react-device-detect';
const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#fff',
        padding: '10px',
        maxHeight: '100%',
        maxWidth: '30vw',
        '@media screen and (max-width: 480px)': {
            maxWidth: '100%',
        },
    },
    paper1: {
        backgroundColor: '#fff',
        padding: '10px',
        maxHeight: '100%',
        maxWidth: '50vw',
        '@media screen and (max-width: 480px)': {
            maxWidth: '100%',
        },
        '& video': {
            width: "280px",
            height: "408px"
        },
    },
    image: {
        maxWidth: '100%',
        height: '100%',
    },
    thumbnail: {
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
        borderRadius: '8px',
        background: 'rgba(255, 255, 255, 0.3)',
        padding: '12px',
        display: 'grid',
        height: '260px',
        placeItems: 'center',
        transition: 'transform 0.2s ease-in-out',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            overflow: "hidden",
            borderRadius: '8px',
            '& :hover': {
                transform: 'scale(1.1)',
            }
        },
        '& video': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            overflow: "hidden",
            borderRadius: '8px',
        },
        '@media screen and (max-width: 480px)': {
            '& img': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                overflow: "hidden",
                borderRadius: '8px',
                '& :hover': {
                    transform: 'scale(1.1)',
                }
            },
            '& video': {
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                overflow: "hidden",
                borderRadius: '8px',
            },
        },
    },
    galleryContainer: {
        borderRadius: '6px',
        padding: '3vw 3vh',
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            padding: '2vw 1vh',
        },
    }
}));

const RenderGallery = (props) => {
    const { galleryData } = props
    const classes = useStyles();
    const imageUrl = _api.imageUrl;
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState({});

    const handleOpen = (image) => {
        setSelectedImage(image);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Grid container gap={3}>
                <Grid item xs={12}>
                    <Grid container className={classes.galleryContainer} justifyContent="space-evenly" gap={3}>
                        <Grid item sx={{ background: 'rgba(255, 255, 255, 0.3)', borderRadius: '8px' }} xs={12}>
                            <Typography align='center' className={`fw-700 ${isMobile ? "fs-18" : "fs-26" } primary`}>Images</Typography>
                        </Grid>
                        {galleryData.map((image, index) => (
                            <>
                                {image?.type === 1 &&
                                    <Grid item className={classes.thumbnail} key={`img_${image.id}`} xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <img src={`${imageUrl}/${image.gallery_file}`} alt={`Thumbnail ${index + 1}`} onClick={() => handleOpen({ type: image.type, pathName: `${imageUrl}/${image.gallery_file}` })} />
                                    </Grid>
                                }
                            </>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={classes.galleryContainer} justifyContent="space-evenly" gap={2}>
                        <Grid item sx={{ background: 'rgba(255, 255, 255, 0.3)', borderRadius: '8px' }} xs={12}>
                            <Typography align='center' className={`fw-700 ${isMobile ? "fs-18" : "fs-26" } primary`}>Videos</Typography>
                        </Grid>
                        {galleryData.map((video, index) => (
                            <>
                                {video?.type === 2 &&
                                    <Grid item className={classes.thumbnail} key={`vid_${video.id}`} xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <video onClick={() => handleOpen({ type: video.type, pathName: `${imageUrl}/${video.gallery_file}` })}>
                                            <source src={`${imageUrl}/${video.gallery_file}`} type="video/mp4" />
                                        </video>
                                    </Grid>
                                }
                            </>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="image-modal"
                aria-describedby="image-modal-description"
            >
                <div className={selectedImage?.type === 1 ? classes.paper : classes.paper1}>
                    {selectedImage?.type === 1 ?
                        <img src={selectedImage.pathName} alt="Selected" className={classes.image} />
                        :
                        <video controls >
                            <source src={selectedImage.pathName} type="video/mp4" />
                        </video>
                    }

                    <Button onClick={handleClose}>Close</Button>
                </div>
            </Modal>
        </div>
    );
};

export default RenderGallery;
