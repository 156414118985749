import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import successReducer from "../reducers/successReducer";

const store = createStore(
    successReducer,
    applyMiddleware(thunk)
);

export default store;
