const styles = {
    mainContainer: {
        width: "100%",
        background: 'radial-gradient(ellipse at 150%, #b85f1f, #013fc5) no-repeat',
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            '& p': {
                textAlign: 'center !important'
            },
        },
    },
    headerImg: {
        '& img': {
            width: '100%'
        },
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            '& img': {
                width: '100vw !important'
            }
        },

    },
    contactBtn: {
        color: '#fff !important',
        fontSize: '20px!important',
        fontWeight: '700!important',
        border: '3px solid #FFFFFF!important',
        borderRadius: '14px!important',
        // padding: '14px 39px !important',
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            fontSize: '14px!important',
            '& svg':{
                width: '14px',
                height: '14px'
            },
            '& img': {
                width: '14px',
                height: '14px'
            }
        },
    },
    sectionTitle: {
        fontWeight: '44px',
    },
    shopPhotoContainer: {
        background: 'rgba(0, 0, 0, 0.4)',
    },
    shopPhoto: {
        '& img': { 
            width: '100%',
            height: '50vh'
        }
    },
    pageContents: {
        padding: '4vh 8vw',
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            padding: '4vh 10vw',
        },
    },
    addressTypo: {
        color: '#fff',
        fontSize: '20px !important',
        fontWeight: '500 !important',
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            fontSize: '14px!important',
        },
    },
    logo: {
        '& img': {
            width: '4vw'
        },
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            justifyContent: 'space-around',
            display: 'flex',
            '& img': {
                width: '35vw'
            }
        },

    },
    mapCard: {
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            maxWidth: '80vw !important',
        },
    },
    datePicker: {
        '& .MuiInputBase-root': {
            border: '1px solid #fff',
            background: '#fff',
            borderRadius: '14px',
        },
        '& .MuiInputBase-input': {
            fontWeight: 700
        }
    },
    lotteryContainer: {
        background: '#fff',
        padding: '10px',
        width: '100 %',
        height: 'auto',
        '& img': {
            maxWidth: '100%',
            width: 'auto',
            height: '23vh',
        },
        '@media screen and (min-width: 1440px)': {
            '& img': {
                height: '21vh',
            }
        },
        '@media screen and (max-width: 1023px) and (min-width: 768px)': {
            '& img': {
            height: '35vh',
            }
        },
        '@media screen and (max-width: 480px)': {
            '& img': {
                width: '80vw',
                height: 'auto',
            }
        },

    },
    lotteryPrize: {
        border: '3px solid blue',
        padding: '20px 50px',
        height: '100%',
    },
    lotteryTitle: {
        color: "blue",
        fontSize: "30px !important",
        fontWeight: '700 !important'
    },
    lotteryAmount: {
        color: "blue",
        fontSize: "60px !important",
        fontWeight: '700 !important'
    },
    resultContainer: {
        background: '#fff',
        padding: '10px',
        maxWidth: '55vw',
        borderRadius: '8px',
        maxHeight: '90vh',
        '& img': {
            width: '100%',
            height: '100%'
        },
        '@media screen and (max-width: 480px)': {
            background: '#fff',
            padding: '10px',
            maxWidth: '100%',
            '& img': {
                width: '74vw',
            }
        }

    },
    emptyResultContainer: {
        padding: '20px 20px',
        background: '#fff',
        borderRadius: '8px',
        width: '55vw',
        height: '90vh',
        '& MuiTypography-root': {
            fontWeight: '700 !important'
        },
        '@media screen and (max-width: 480px)': {
            width: '80vw',
            maxHeight: "360px" 
        }
    },
    centerContent: {
        display: 'flex',
        justifyContent: 'center',
    },
    iconImage: {
        '& img' :{
            width: '30px',
            height: '30px',
        }
    },
    aboutUsTypo: {
        '& .MuiTypography-root': {
            color: '#fff',
            fontWeight: 500,
            fontSize: '14px',
        }
    },
    alignContainer: {
        '@media screen and (max-width: 1023px) and (min-width: 768px)': {
            alignItems: "flex-start !important"
        },
        '@media screen and (max-width: 767px) and (min-width: 425px)': {
            justifyContent: "center"
        },
    }
}




export default styles