import { getRequest, postRequest, deleteRequest } from '../../utils/apiHelper';

export const successService = {
    getSummarySheet,
    getGalleryData,
    getLotteryData,
    uploadSummarySheet,
    saveGalleryData,
    saveLotteryData,
    deleteSummarySheet,
    deleteGalleryData,
    deleteLotteryData,
    loginDetail
}

// Get Summary Sheet Data
async function getSummarySheet() {
    const response = await getRequest('success/getSummary')
    return response;
}

// Upload Summary Sheet Data
async function uploadSummarySheet(formData) {
    const response = await postRequest('success/uploadSummary', formData, true)
    return response;
}

//Delete Summary Sheet Data
async function deleteSummarySheet(params) {
    const response = await deleteRequest(`success/deleteSummary/${params.id}`, params)
    return response;
}

// Get Gallery Data
async function getGalleryData() {
    const response = await getRequest('success/getGallery')
    return response;
}

// Upload Gallery Data
async function saveGalleryData(formData) {
    const response = await postRequest('success/saveGallery', formData, true)
    return response;
}

// check login cred
async function loginDetail(body) {
    const response = await postRequest('success/login', body)
    return response;
}

//Delete Gallery Data
async function deleteGalleryData(params) {
    const response = await deleteRequest(`success/deleteGallery/${params.id}`, params)
    return response;
}

// Get Lottery Data
async function getLotteryData() {
    const response = await getRequest('success/getLottery')
    return response;
}

// Upload Lottery Data
async function saveLotteryData(formData) {
    const response = await postRequest('success/saveLottery', formData, true)
    return response;
}

//Delete Lottery Data
async function deleteLotteryData(params) {
    const response = await deleteRequest(`success/deleteLottery/${params.id}`, params)
    return response;
}