export const successConstant = {

    //Get Summary Sheet Images
    GET_SUMMARY_SHEET_R: "GET_SUMMARY_SHEET_R",
    GET_SUMMARY_SHEET_S: "GET_SUMMARY_SHEET_S",
    GET_SUMMARY_SHEET_F: "GET_SUMMARY_SHEET_F",

    //Save Summary Sheet Images
    SAVE_SUMMARY_SHEET_R: "SAVE_SUMMARY_SHEET_R",
    SAVE_SUMMARY_SHEET_S: "SAVE_SUMMARY_SHEET_S",
    SAVE_SUMMARY_SHEET_F: "SAVE_SUMMARY_SHEET_F",

    //Upload Summary Sheet Images
    UPLOAD_SUMMARY_SHEET_R: "UPLOAD_SUMMARY_SHEET_R",
    UPLOAD_SUMMARY_SHEET_S: "UPLOAD_SUMMARY_SHEET_S",
    UPLOAD_SUMMARY_SHEET_F: "UPLOAD_SUMMARY_SHEET_F",

    //Delete Summary Sheet Images
    DELETE_SUMMARY_SHEET_R: "DELETE_SUMMARY_SHEET_R",
    DELETE_SUMMARY_SHEET_S: "DELETE_SUMMARY_SHEET_S",
    DELETE_SUMMARY_SHEET_F: "DELETE_SUMMARY_SHEET_F",

    //Get Gallery Images
    GET_GALLERY_DATA_R: "GET_GALLERY_DATA_R",
    GET_GALLERY_DATA_S: "GET_GALLERY_DATA_S",
    GET_GALLERY_DATA_F: "GET_GALLERY_DATA_F",

    //Save Gallery Images
    SAVE_GALLERY_DATA_R: "SAVE_GALLERY_DATA_R",
    SAVE_GALLERY_DATA_S: "SAVE_GALLERY_DATA_S",
    SAVE_GALLERY_DATA_F: "SAVE_GALLERY_DATA_F",

    //Delete Gallery Images
    DELETE_GALLERY_DATA_R: "DELETE_GALLERY_DATA_R",
    DELETE_GALLERY_DATA_S: "DELETE_GALLERY_DATA_S",
    DELETE_GALLERY_DATA_F: "DELETE_GALLERY_DATA_F",

    //Get Lottery data
    GET_LOTTERY_DATA_R: "GET_LOTTERY_DATA_R",
    GET_LOTTERY_DATA_S: "GET_LOTTERY_DATA_S",
    GET_LOTTERY_DATA_F: "GET_LOTTERY_DATA_F",

    //Save Lottery data
    SAVE_LOTTERY_DATA_R: "SAVE_LOTTERY_DATA_R",
    SAVE_LOTTERY_DATA_S: "SAVE_LOTTERY_DATA_S",
    SAVE_LOTTERY_DATA_F: "SAVE_LOTTERY_DATA_F",

    //Delete Lottery data
    DELETE_LOTTERY_DATA_R: "DELETE_LOTTERY_DATA_R",
    DELETE_LOTTERY_DATA_S: "DELETE_LOTTERY_DATA_S",
    DELETE_LOTTERY_DATA_F: "DELETE_LOTTERY_DATA_F",

    //Update reducer
    UPDATE_SUMMARY_REDUCER: "UPDATE_SUMMARY_REDUCER",
    UPDATE_GALLERY_REDUCER: "UPDATE_GALLERY_REDUCER",
    UPDATE_LOTTERY_REDUCER: "UPDATE_LOTTERY_REDUCER",

    //Login 
    SUCCESS_LOGIN_R: "SUCCESS_LOGIN_R",
    SUCCESS_LOGIN_S: "SUCCESS_LOGIN_S",
    SUCCESS_LOGIN_F: "SUCCESS_LOGIN_F",
    
}