let API_POINTS = {
    url: 'http://localhost:8000/api'
}

const SERVER_POINT = process.env.REACT_APP_BUILD_TO || "LOCAL";

switch (SERVER_POINT) {
    case "PROD":
        API_POINTS = {
            url: 'https://api.successlotteryagency.com/api',
            imageUrl: 'https://api.successlotteryagency.com'
        };
        break;
    default:
        API_POINTS = {
            url: 'http://localhost:8000/api',
            imageUrl: 'http://localhost:8000'
        }
        break;
}

export const _api = API_POINTS;
