import { Grid, Typography } from "@mui/material";
import React from "react";


const ShopAddress = ({ classes }) => {
    return(
            <Grid container direction={"row"}>
                <Grid item xs={12}>
                    <Typography className={classes.addressTypo}>Shop Address:</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography className={classes.addressTypo}>Success Lottery Agency,</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.addressTypo}>Near to ration shop,</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.addressTypo}>Bharathi Nagar, Meenakshipuram,</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.addressTypo}>Palakkad, Kerala – 678533.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
    )
}

export default ShopAddress