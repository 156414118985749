import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { withStyles } from "@mui/styles"

import { successAction } from "../../redux/actions/successAction";
import { useDispatch } from "react-redux";

import style from "./styles";



function LoginPage (props) {

    const { classes } = props

        const [username, setUsername] = useState('');
        const [password, setPassword] = useState('');

        const dispatch = useDispatch()
    const navigate = useNavigate();

        const handleLogin = () => {
            dispatch(successAction.loginDetail({ username: username, password: password }, () => {
                navigate('/dashboard');
            }))
        };

    return(
        <Grid container className={classes.loginContainer} spacing={9}>
            <Grid item xs={12}>
                <Grid container>
                    {/* <Grid item className={classes.logo}>
                        <img src={successLogo} alt='logo' />
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent='center'> 
                    <Grid item>
                        <Grid container className={classes.loginCard} justifyContent='center' direction='column'>
                            <Grid item>
                                <Typography variant="h5">Login</Typography>
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    label="Username"
                                    fullWidth
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    label="Password"
                                    type="password"
                                    fullWidth
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Button variant="contained" color="primary" onClick={handleLogin} style={{ marginTop: 16 }}>
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary" onClick={handleLogin} style={{ marginTop: 16 }}>
                                            Login
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Grid>
            
        </Grid>
    )
}

export default withStyles(style)(LoginPage)

