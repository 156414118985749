const styles = {
    mainContainer: {
        background: 'radial-gradient(ellipse at 150%, #b85f1f, #013fc5) no-repeat',
        height: '100%',
        padding: '4vh 8vw',
        '@media screen and (max-width: 480px) and (min-width: 315px)': {
            height: '100%',
        },
    },
    headerImg: {
        '& img': {
            width: '100%'
        }
    },
    pageContents: {
        padding: '4vh 8vw'
    },
    addressTypo: {
        color: '#fff',
        fontSize: '20px !important',
        fontWeight: '500 !important',
    },
    logo: {
        '& img': {
            width: '4vw'
        }
    },
    datePicker: {
        '& .MuiInputBase-root': {
            border: '1px solid #fff',
            background: '#fff',
            borderRadius: '50px',
        }
    },
    uploadCard: {
        border: '1px solid #fff',
        borderRadius: '12px',
        padding: '10px',
    },
    uploadForm: {
        '& .MuiInputBase-root': {
            border: '1px solid #fff',
            background: '#fff',
            borderRadius: '12px',
        }
    },
    uploadInput: {
        '& .MuiInputBase-input': {
            border: '1px solid #fff',
            background: '#fff',
            borderRadius: '12px',
            padding: '16px'
        }
    },
}

export default styles