import axios from 'axios';

import { _api} from "../config/environment";


const apiBaseUrl = _api.url;

/**
 * GET request
 * @param {*} endpoint
 */
export const getRequest = async (endpoint) => {
    return makeRequest(`${apiBaseUrl}/${endpoint}`, "GET");
};

/**
 * PUT requests
 * @param {* The put endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the put method contains any images, <false> otherwise.} isFormData
 */
export const putRequest = async (endpoint, body, isFormData) => {
    return makeRequest(`${apiBaseUrl}/${endpoint}`, "PUT", body, isFormData);
};

/**
 * POST request
 * @param {* The post endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the post method contains any images, <false> otherwise.} isFormData
 */
export const postRequest = async (endpoint, body, isFormData, responseType) => {    
    return makeRequest(`${apiBaseUrl}/${endpoint}`, "POST", body, isFormData, responseType);
};

/**
 * DELETE request
 * @param {* The delete endpoint} endpoint
 */
export const deleteRequest = async (endpoint, body) => {
    return makeRequest(`${apiBaseUrl}/${endpoint}`, "DELETE", body);
};

/**
 * api make request
 * @param {*} endpoint
 * @param {*} verb
 * @param {*} body
 * @param {*} isFormData
 */
const makeRequest = (endpoint, verb, data, isFormData = false, responseType) => {
    const requestOptions = {
        method: verb,
        url: endpoint,
        headers: getHeaders(isFormData, endpoint),
        responseType: responseType,
        data
    };
    return axios(requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            throw error;
        });
};

/**
 * Handler for the response recieved from fetch request
 * @param {* The response recieved from fetch request} response
 */
const handleResponse = (response) => {
    if (response.status === 200) {
        if (response.data.http_code === 200) {
            return response.data;
        } else if (response.data.http_code === 307) {
            // history.push({ pathname: "/resetpassword/" + response.data.hash + "/" + response.data.key });
            // throw response.data.message;
        } else {
            throw response.data.message;
        }
    } else {
        throw new Error("Server connection issue");
    }
};

/**
 * Prepares headers for the request
 * @param {* <true> if the request contains any images, <false> otherwise.} isFormData
 */
const getHeaders = (isFormData, endpoint, userIp) => {
    // const token = getToken();
    const headers = {};
    headers.userip = userIp
    // if (token) {
    //     headers.Authorization = `Bearer ${token}`;
    // }
    if (!isFormData) {
        headers["Content-Type"] = "application/json";
    }
    return headers;
};



