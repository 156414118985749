import React, { useEffect } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; 

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const Map = () => {
    const customIcon = L.icon({
        iconUrl: markerIcon,
        shadowUrl: markerShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41],
    });

        useEffect(() => {
            // Create a map instance
            const map = L.map('mapid').setView([10.63317118174035, 76.86084729640515], 15);

            // Add a tile layer (replace the URL with your desired tile layer)
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Map data © <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
            }).addTo(map);

            // Add a marker for your shop location
            L.marker([10.63317118174035, 76.86084729640515], { icon: customIcon }).addTo(map)
                .bindPopup('Success Lottery Agency');
            return () => map.remove();
        }, []); // Make sure to pass an empty array as the second argument to useEffect

    return <div id="mapid" style={{ height: '250px' }}></div>;

};

export default Map;

