import React from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './containers/Home';
import LoginPage from './containers/Login';
import Dashboard from "./containers/Dashboard"

const PrivateRoute = ({ element }) => {
  const isAuthenticated = localStorage.getItem('token');
  return isAuthenticated ? element : <Navigate to="/admin-login" />;
};

function App() {

  return (
    <Router>
        <Routes>
          <Route path='/' exact element={<HomePage />} />
          <Route path="/admin-login" element={<LoginPage />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
        </Routes>      
    </Router>
  );
}

export default App;
