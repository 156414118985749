import React from 'react';

export const ArrowCircleDown = (props) => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 29.3332C23.3638 29.3332 29.3333 23.3636 29.3333 15.9998C29.3333 8.63604 23.3638 2.6665 16 2.6665C8.63619 2.6665 2.66666 8.63604 2.66666 15.9998C2.66666 23.3636 8.63619 29.3332 16 29.3332Z" stroke="#184EC2" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.2933 14.3198L16 19.0132L20.7067 14.3198" stroke="#184EC2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)

export const Calender = (props) => (
    <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 2.34961V5.8737" stroke="#184EC2" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18 2.34961V5.8737" stroke="#184EC2" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.9375 10.6777H23.0625" stroke="#184EC2" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M23.625 9.9853V19.9702C23.625 23.4943 21.9375 25.8437 18 25.8437H9C5.0625 25.8437 3.375 23.4943 3.375 19.9702V9.9853C3.375 6.46121 5.0625 4.11182 9 4.11182H18C21.9375 4.11182 23.625 6.46121 23.625 9.9853Z" stroke="#184EC2" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.6565 16.0932H17.6666" stroke="#184EC2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.6565 19.6171H17.6666" stroke="#184EC2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.4949 16.0932H13.505" stroke="#184EC2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.4949 19.6171H13.505" stroke="#184EC2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.3311 16.0932H9.34121" stroke="#184EC2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.3311 19.6171H9.34121" stroke="#184EC2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export const PhoneIcon = (props) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5566 12.5415C20.055 12.5415 19.6583 12.1332 19.6583 11.6432C19.6583 11.2115 19.2266 10.3132 18.5033 9.53154C17.7916 8.7732 17.01 8.32987 16.3566 8.32987C15.855 8.32987 15.4583 7.92154 15.4583 7.43154C15.4583 6.94154 15.8666 6.5332 16.3566 6.5332C17.5233 6.5332 18.7483 7.1632 19.8216 8.29487C20.825 9.35654 21.4666 10.6749 21.4666 11.6315C21.4666 12.1332 21.0583 12.5415 20.5566 12.5415Z" fill="white" />
        <path d="M24.7683 12.5418C24.2666 12.5418 23.87 12.1335 23.87 11.6435C23.87 7.50183 20.4983 4.14183 16.3683 4.14183C15.8666 4.14183 15.47 3.7335 15.47 3.2435C15.47 2.7535 15.8666 2.3335 16.3566 2.3335C21.49 2.3335 25.6666 6.51016 25.6666 11.6435C25.6666 12.1335 25.2583 12.5418 24.7683 12.5418Z" fill="white" />
        <path d="M13.755 16.5785L9.93998 20.3935C9.51998 20.0202 9.11165 19.6352 8.71498 19.2385C7.51331 18.0252 6.42831 16.7535 5.45998 15.4235C4.50331 14.0935 3.73331 12.7635 3.17331 11.4452C2.61331 10.1152 2.33331 8.8435 2.33331 7.63016C2.33331 6.83683 2.47331 6.0785 2.75331 5.3785C3.03331 4.66683 3.47665 4.0135 4.09498 3.43016C4.84165 2.69516 5.65831 2.3335 6.52165 2.3335C6.84831 2.3335 7.17498 2.4035 7.46665 2.5435C7.76998 2.6835 8.03831 2.8935 8.24831 3.19683L10.955 7.01183C11.165 7.3035 11.3166 7.57183 11.4216 7.8285C11.5266 8.0735 11.585 8.3185 11.585 8.54016C11.585 8.82016 11.5033 9.10016 11.34 9.3685C11.1883 9.63683 10.9666 9.91683 10.6866 10.1968L9.79998 11.1185C9.67165 11.2468 9.61331 11.3985 9.61331 11.5852C9.61331 11.6785 9.62498 11.7602 9.64831 11.8535C9.68331 11.9468 9.71831 12.0168 9.74165 12.0868C9.95165 12.4718 10.3133 12.9735 10.8266 13.5802C11.3516 14.1868 11.9116 14.8052 12.5183 15.4235C12.9383 15.8318 13.3466 16.2285 13.755 16.5785Z" fill="white" />
        <path d="M25.6317 21.3849C25.6317 21.7115 25.5733 22.0499 25.4567 22.3766C25.4217 22.4699 25.3867 22.5632 25.34 22.6565C25.1417 23.0766 24.885 23.4732 24.5467 23.8466C23.975 24.4766 23.345 24.9316 22.6333 25.2232C22.6217 25.2232 22.61 25.2349 22.5983 25.2349C21.91 25.5149 21.1633 25.6665 20.3583 25.6665C19.1683 25.6665 17.8967 25.3866 16.555 24.8149C15.2133 24.2432 13.8717 23.4732 12.5417 22.5049C12.0867 22.1666 11.6317 21.8282 11.2 21.4665L15.015 17.6515C15.3417 17.8965 15.6333 18.0832 15.8783 18.2115C15.9367 18.2349 16.0067 18.2699 16.0883 18.3049C16.1817 18.3399 16.275 18.3515 16.38 18.3515C16.5783 18.3515 16.73 18.2816 16.8583 18.1532L17.745 17.2782C18.0367 16.9866 18.3167 16.7649 18.585 16.6249C18.8533 16.4615 19.1217 16.3799 19.4133 16.3799C19.635 16.3799 19.8683 16.4265 20.125 16.5315C20.3817 16.6365 20.65 16.7882 20.9417 16.9866L24.8033 19.7282C25.1067 19.9382 25.3167 20.1832 25.445 20.4749C25.5617 20.7665 25.6317 21.0582 25.6317 21.3849Z" fill="white" />
    </svg>
)

export const FacebookIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="34px" height="34px"><path fill="#3F51B5" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z" /><path fill="#FFF" d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z" /></svg>
)

