import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Grid, Divider, IconButton, Link } from '@mui/material';
import { withStyles } from "@mui/styles"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { _api } from "../../config/environment";

//Styles
import './App.css';
import styles from './styles';

//Import components
import Map from '../../components/GoogleMap';

//Import Images
import whatsappIcon from "../../assets/png/whatsapp.png";
import instagram from "../../assets/png/instagram.png";
import youtube from "../../assets/png/youtube.png";
import facebook from "../../assets/png/facebook.png";
import headerImage from "../../assets/image/headerImage.jpeg";
import shopImage from "../../assets/image/shopImage.jpeg"

//import svg 
import { PhoneIcon } from "../../assets/svg"
import { useDispatch, useSelector } from 'react-redux';
import { successAction } from '../../redux/actions/successAction';
import RenderGallery from '../../components/RenderGallery';
import ShopAddress from './ShopAddress';

function HomePage(props) {
    const { classes } = props;

    const dispatch = useDispatch();

    const imageUrl = _api.imageUrl;
    const [dates, setDates] = useState([]);
    const today = moment();

    const [resultDate, setResultDate] = useState(dayjs(Date.now()));
    const [currentResult, setCurrentResult] = useState({});
    const { summarySheet, galleryData, lotteryData } = useSelector(s => s);
    useEffect(() => {
        const datesArray = [];
        for (let i = 1; i <= 7; i++) {
            const date = today.clone().subtract(i, 'days');
            datesArray.push(date.format("DD MMM, YYYY"));
        }
        setDates(datesArray);
    }, []);

    useEffect(() => {
        dispatch(successAction.getSummarySheet())
        dispatch(successAction.getGalleryData())
        dispatch(successAction.getLotteryData())
    }, [dispatch])

    useEffect(() => {
        const selectedDate = summarySheet?.find((result) => {
            return moment(result?.sheet_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
        })
        if (selectedDate) {
            setCurrentResult({ path: selectedDate?.image_file, title: selectedDate?.title, sheetDate: moment(selectedDate?.sheet_date).format("DD-MM-YYYY") })
        } else {
            const availableData = summarySheet[summarySheet.length - 1]
            setResultDate(dayjs(availableData?.sheet_date));
            setCurrentResult({ path: availableData?.image_file, title: availableData?.title, sheetDate: moment(availableData?.sheet_date).format("DD-MM-YYYY") })
        }
    }, [summarySheet])

    const handleDateClick = (date) => {
        let selectedDay = dayjs(date)
        setResultDate(dayjs(date));
        const selectedDate = summarySheet?.find((result) => {
            return moment(result?.sheet_date).format("YYYY-MM-DD") === moment(date, "DD MMM, YYYY").format("YYYY-MM-DD")
        })
        moment(selectedDate?.sheet_date).format("DD-MM-YYYY")
        if (selectedDate) {
            setCurrentResult({ path: selectedDate?.image_file, title: selectedDate?.title, sheetDate: moment(selectedDay.$d).format("DD-MM-YYYY") })
        } else {
            setCurrentResult({ sheetDate: moment(selectedDay.$d).format("DD-MM-YYYY") })
        }
    }

    const handleDateChange = (event, date) => {
        setResultDate(event.$d);
        const selectedDate = summarySheet?.find((result) => {
            return moment(result?.sheet_date).format("YYYY-MM-DD") === moment(event.$d).format("YYYY-MM-DD")
        })
        if (selectedDate) {
            setCurrentResult({ path: selectedDate?.image_file, title: selectedDate?.title, sheetDate: moment(selectedDate?.sheet_date).format("DD-MM-YYYY") })
        } else {
            setCurrentResult({ sheetDate: moment(event.$d).format("DD-MM-YYYY") })
        }
    };

    const handleSingleLotteryGroup = () => {
        window.location.href = `tel:${+918668064247}`;
    }

    const handleBumperLotteryGroup = () => {
        const url = `https://wa.me/8668064247`;
        window.open(url, '_blank');
    }

    const handleFacebook = () => {
        const url = `https://www.facebook.com/profile.php?id=100090194780984&mibextid=ZbWKwL`;
        window.open(url, '_blank');
    }

    const handleInstagram = () => {
        const url = `https://www.instagram.com/success.lottery?igsh=MWpydDU4YXNnM3kxeQ==`;
        window.open(url, '_blank');
    }

    const handleYoutube = () => {
        const url = `https://youtube.com/@SUCCESSLOTTERYAGENCYOFFICIAL?si=5gWA3a_pDBFynfrL`;
        window.open(url, '_blank');
    }

    const handleMore = () => {
        const url = `https://result.keralalotteries.com/`;
        window.open(url, '_blank');
    }
    return (
        <Grid container className={classes.mainContainer} justifyContent={isMobile && "center"} direction='column' wrap="nowrap">
            <Grid item className={classes.headerImg}>
                <img src={headerImage} alt='headerImage' />
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.shopPhotoContainer} justifyContent={"center"}>
                    <Grid item className={classes.shopPhoto}>
                        <img src={shopImage} alt='shop' />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container className={classes.pageContents} gap={3}>
                    <Grid item xs={12}>
                        <Grid container justifyContent='space-between' gap={2}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ShopAddress classes={classes} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                                <Grid container direction={"column"} className={classes.alignContainer} alignItems={"center"} gap={1}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.addressTypo}>Working hours: 09.00 AM to 09.00 PM (All Days) </Typography>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Grid container justifyContent={isMobile && 'center'} gap={2}>
                                            <Grid item>
                                                <Button className={classes.contactBtn} variant='contained' onClick={handleSingleLotteryGroup} startIcon={<PhoneIcon />}>8668064247</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button className={classes.contactBtn} sx={{ textTransform: "none" }} variant='contained' onClick={handleBumperLotteryGroup} startIcon={<img src={whatsappIcon} alt='whatsappIcon' />}>WhatsApp Group</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent={isMobile && 'center'}>
                                            <Grid item className={classes.iconImage}>
                                                <IconButton onClick={handleInstagram}><img src={instagram} alt='instagram' /></IconButton>
                                            </Grid>
                                            <Grid item className={classes.iconImage}>
                                                <IconButton onClick={handleYoutube}><img src={youtube} alt='youtube' /></IconButton>
                                            </Grid>
                                            <Grid item className={classes.iconImage}>
                                                <IconButton onClick={handleFacebook}><img src={facebook} alt='facebook' /></IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container gap={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container justifyContent={"flex-start"} alignItems={"center"} gap={2}>
                                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                                        <Typography className={`fw-700 ${isMobile ? 'fs-18' : 'fs-32'} primary`}>Today Result</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Grid container justifyContent={"center"} sx={{ background: 'rgba(0, 0, 0, 0.4)', padding: '5px 8px', borderRadius: '8px' }} gap={2}>
                                            <Grid item>
                                                <Typography className={`fw-700 ${isMobile ? 'fs-14' : 'fs-18'} secondary1`}>{`[${currentResult?.sheetDate}]`}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={`fw-700 ${isMobile ? 'fs-14' : 'fs-18'} primary`}>{`(Draw Time 3 PM Every Day)`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent='space-between' gap={2}>
                                    {(currentResult?.hasOwnProperty('path')) ?
                                        <Grid item className={classes.resultContainer} xs={12} md={7} lg={7} xl={7}>
                                            <img src={`${imageUrl}/${currentResult.path}`} alt={currentResult.title} />
                                        </Grid>
                                        :
                                        <Grid item className={classes.emptyResultContainer} xs={12} md={8} lg={7} xl={7}>
                                            <Typography align='center' color='secondary' className={isMobile && 'fs-14'} variant={isMobile && 'h5'}>Todays Result yet to be uploaded...!</Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={12} md={3} lg={3} xl={3}>
                                        <Grid container direction='column' alignItems={isMobile && 'center'} pt={isMobile && 2} gap={2}>
                                            <Grid item className={isMobile && classes.centerContent} xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        className={classes.datePicker}
                                                        format="DD MMM, YYYY"
                                                        value={resultDate}
                                                        onChange={handleDateChange}
                                                        slotProps={{
                                                            openPickerButton: {
                                                                color: 'primary',
                                                            },
                                                            inputAdornment: {
                                                                position: 'start',
                                                            },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <Typography className={`fw-700 primary ${isMobile ? 'fs-18' : 'fs-32'}`}>Previous Results</Typography>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <Grid container direction='column'>
                                                    {dates.map((ele) => (
                                                        <Grid item className={isMobile && classes.centerContent} xs={12} sm={12} md={6} lg={6} xl={6}>
                                                            <Button
                                                                key={ele}
                                                                variant='standard'
                                                                className={`primary ${isMobile ? ' fs-12' : 'fs-26'} alignTextLeft p0`}
                                                                onClick={() => { handleDateClick(ele) }}
                                                            >
                                                                {ele}
                                                            </Button>

                                                        </Grid>
                                                    ))}
                                                    <Grid item>
                                                        <Button variant='standard' className={`primary ${isMobile ? ' fs-12' : 'fs-26'} alignTextLeft p0`}
                                                            onClick={handleMore}>more</Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container direction='column'>
                            <Grid item>
                                <Typography className={`fw-700 ${isMobile ? 'fs-18' : 'fs-32'} primary`}>Gallery</Typography>
                            </Grid>
                            <Grid item>
                                <RenderGallery galleryData={galleryData} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container gap={2}>
                            <Grid item xs={12}>
                                <Typography className={`fw-700 ${isMobile ? 'fs-18' : 'fs-32'} primary`}>Lotteries</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent={"center"} gap={3}>
                                    <Grid item sx={{ background: 'rgba(255, 255, 255, 0.3)', borderRadius: '8px' }} xs={12}>
                                        <Typography align='center' className={`fw-700 ${isMobile ? "fs-18" : "fs-26" } primary`}>Daily Tickets</Typography>
                                    </Grid>

                                    {lotteryData?.map((lottery) => (
                                        <>
                                            {lottery.type === 1 &&
                                                <Grid item xs={12} sm={12} md={6} lg={3.5} xl={3.5} >
                                                    <Grid container alignItems="center" justifyContent='center' gap={1}>
                                                        <Grid item className={classes.lotteryContainer} xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Grid container justifyContent='space-between'>
                                                                        <Grid item>
                                                                            <Typography color='secondary' align='left' className={`fw-700 ${isMobile && 'fs-12'} upperCase`}>Daily</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography color='secondary' align='right' className={`fw-700 ${isMobile && 'fs-12'} upperCase`}>{lottery.title}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <img src={`${imageUrl}/${lottery.lottery_file}`} alt={lottery.title} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </>
                                    ))
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" gap={3}>
                                    <Grid item sx={{ background: 'rgba(255, 255, 255, 0.3)', borderRadius: '8px' }} xs={12}>
                                        <Typography align='center' className={`fw-700 ${isMobile ? "fs-18" : "fs-26" } primary`}>Bumper Tickets</Typography>
                                    </Grid>
                                    {lotteryData?.map((lottery) => (
                                        <>
                                            {lottery.type === 2 &&
                                                <Grid item xs={12} sm={12} md={6} lg={3.5} xl={3.5} >
                                                    <Grid container alignItems="center" justifyContent='center' gap={1}>
                                                        <Grid item className={classes.lotteryContainer} xs={12}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Grid container justifyContent='space-between'>
                                                                        <Grid item sx={{ background: 'rgba(255, 255, 255, 0.3)', borderRadius: '8px' }}>
                                                                            <Typography color='secondary' align='left' className={`fw-700 ${isMobile && 'fs-12'} upperCase`}>Bumper</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography color='secondary' align='right' className={`fw-700 ${isMobile && 'fs-12'} upperCase`}>{lottery.title}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <img src={`${imageUrl}/${lottery.lottery_file}`} alt={lottery.title} />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography color='secondary' align='center' className={`fw-700 ${isMobile && 'fs-12'} upperCase`}>{lottery.lottery_prize}</Typography>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </>
                                    ))
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container gap={2}>
                            <Grid item xs={12}>
                                <Typography className={`fw-700 ${isMobile ? 'fs-18' : 'fs-32'} primary`}>About Us</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container className={classes.aboutUsTypo} gap={2}>
                                    <Grid item xs={12}>
                                        <Typography>Kerala State Lottery Ticket is a government lottery founded in 1967 under the Kerala government's lottery department, it sells tickets to authorized agencies like us. All Indian people can buy Kerala government lottery tickets and win prizes with their luck.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Success Lottery Agency is one of the leading Kerala lottery agents in Palakkad. We are a Kerala government-authorized agency and we are a wholesale and retailer. We have more than 30,000 customers all over India. </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>With your support and luck, We assist many people every day to get many winning prizes from the Kerala lottery tickets. We can help you to claim the winning amounts even if you didn't buy tickets from our shop. Remember after a draw, the due date for a claim is only 25 days. Don't worry We will help you to claim the prize amount whatever it is.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>You can buy 2 types of tickets</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{`1) Daily Tickets: Daily Result @ 3 PM - Every day the Kerala Government gives crores, lakhs, and also thousands of rupees as  winning amounts to many lucky people.`}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{`2) Bumper Tickets: Result in 2 months once - Kerala Government gives prizes up to 25 crores every two months once.`}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>You can check all the winning results on our websites or the Kerala government website as well using this link Link: https://result.keralalotteries.com/</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Like many lucky people, you can also win many prizes every day with your luck!! We have tickets starting from Rs.40.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>Contact us for more details - 8668064247.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container className={classes.aboutUsTypo} gap={2}>
                                    <Grid item xs={12}>
                                        <Typography>Daily Winning amount will be</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>1st prize: Rs. 80,00,000 (For 1 person)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>2nd Prize: Rs. 10,00,000 (For 1 person)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>3rd Prize: Rs. 5,00,000 (For 1 person)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>4th Prize: Rs. 1,00,000 (For 1 person)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>5th Prize: Rs. 5,000 (For 24,840 persons)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>6th Prize: Rs. 2,000 (For 12,960 persons)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>7th Prize: Rs. 1,000 (For 25,920 persons)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>8th Prize: Rs. 500 (For 1,03,680 persons)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>9th Prize: Rs. 200 (For 1,22,020 persons)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>10th Prize: Rs. 100 (For 1,36,080 persons)</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>(Please Note: The prize amount and no.of prizes will be change based on the ticket)</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container gap={isMobile && 2}>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography className={`fw-700 ${isMobile ? 'fs-18' : 'fs-32'} primary`}>Contact Us</Typography>
                                    </Grid>
                                    <Grid item xs={12}> 
                                        <ShopAddress classes={classes} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Grid container direction={"column"} className={classes.alignContainer} alignItems={isMobile ? 'center' : "left"} gap={3}>
                                    <Grid item xs={12}>
                                        <Button className={classes.contactBtn} variant='contained' onClick={handleSingleLotteryGroup} startIcon={<PhoneIcon />}>8668064247</Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button className={classes.contactBtn} sx={{ textTransform: "none" }} variant='contained' onClick={handleBumperLotteryGroup} startIcon={<img src={whatsappIcon} alt='whatsappIcon' />}>WhatsApp Group</Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent={isMobile && 'center'}>
                                            <Grid item className={classes.iconImage}>
                                                <IconButton onClick={handleInstagram}><img src={instagram} alt='instagram' /></IconButton>
                                            </Grid>
                                            <Grid item className={classes.iconImage}>
                                                <IconButton onClick={handleYoutube}><img src={youtube} alt='youtube' /></IconButton>
                                            </Grid>
                                            <Grid item className={classes.iconImage}>
                                                <IconButton onClick={handleFacebook}><img src={facebook} alt='facebook' /></IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className={`fw-700 ${isMobile ? 'fs-18' : 'fs-18'} primary`}>Email: <Link className={`fw-700 ${isMobile ? 'fs-14' : 'fs-18'} primary`} onClick={() => window.location = 'mailto:contact.successagency@gmail.com'}>contact.successagency@gmail.com</Link></Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.mapCard} xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Map />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignItems='center' justifyContent='center'>
                            <Grid item>
                                <Typography className={`primary ${isMobile ? 'fs-12' : 'fs-20'}`} >© Copyright 2024 Success Lottery Agency!</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
HomePage.defaultProps = {
    todayDate: dayjs(Date.now()),
};

// prop types
HomePage.propTypes = {
    todayDate: PropTypes.any,
};

export default withStyles(styles)(HomePage);


